import React from "react";
import SEO from "../components/seo";
import Partner from "../sections/Partners";


const Partners = () => {
  return (
    <>
      <Partner  />

    </>
  );
};
export default Partners;
export const Head = () => {
  return <SEO title="Partners" description="Partner Program" />;
};